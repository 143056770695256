import { withDependencies, named } from '@wix/thunderbolt-ioc'
import { FeatureStateSymbol } from '@wix/thunderbolt-symbols'
import { PageTransitionsApiFactory } from './types'
import { name } from './symbols'

const pageTransitionsApiFactory: PageTransitionsApiFactory = (featureState) => {
	return {
		disableNextTransition: () =>
			featureState.update(() => ({
				...featureState.get(),
				nextTransitionEnabled: false,
			})),
	}
}

export const PageTransitionsApi = withDependencies([named(FeatureStateSymbol, name)], pageTransitionsApiFactory)
