import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { PageTransitionsWillMount } from './pageTransitions'
import { PageTransitionsApi } from './pageTransitionsApi'
import { PageTransitionsCompleted } from './pageTransitionsCompleted'
import { IPageTransitionsCompleted } from './IPageTransitionsCompleted'
import { PageTransitionsSymbol, PageTransitionsCompletedSymbol } from './symbols'
import { LifeCycle } from '@wix/thunderbolt-symbols'

export const page: ContainerModuleLoader = (bind) => {
	bind(PageTransitionsCompletedSymbol).to(PageTransitionsCompleted)
	bind(PageTransitionsSymbol).to(PageTransitionsApi)
	bind(LifeCycle.PageWillMountHandler).to(PageTransitionsWillMount)
}

export { PageTransitionsSymbol, PageTransitionsCompletedSymbol, IPageTransitionsCompleted }
export { IPageTransition } from './types'
